<template>
  <div class="table">
    <div class="th">
      <span>Referral Source</span>
    </div>
    <div
      class="tr">
      <span>Name</span>
      <span>{{form.name}}
      </span>
    </div>
    <div
      class="tr">
      <span>Contact Phone Number</span>
      <span>{{form.mobile_no}}
      </span>
    </div>
  </div>
</template>

<script>
import MixinFormat from '@/mixins/format'
import MixinOption from '@/mixins/options'
export default {
  name: 'referralInfo',
  mixins: [MixinFormat,MixinOption],
  props:{
    form:{
      type:[Object,Array],
      default:()=>({})
    }
  }
}
</script>

<style scoped>

</style>
