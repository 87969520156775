<template>
  <section>
    <div class="table">
      <div class="th">
        <span>Excess</span>
      </div>
      <div v-if="cover_type==2||form.class_id===1004">
        <div></div>
        <div
          class="tr">
          <span>Own Damage</span>
          <span>{{excess.own_damage}}
          </span>
        </div>
        <div
          class="tr">
          <span>Parking</span>
          <span>{{excess.parking|formatMoney(2,true)}}
          </span>
        </div>
        <div
          class="tr">
          <span>Unnamed Driver</span>
          <span>{{excess.unnamed_driver|formatMoney(2,true)}}
          </span>
        </div>
        <div
          class="tr">
          <span>Young Driver</span>
          <span>{{excess.young_driver|formatMoney(2,true)}}
          </span>
        </div>
        <div
          class="tr">
          <span>Inexperienced Driver</span>
          <span>{{excess.inexperienced_driver|formatMoney(2,true)}}
          </span>
        </div>
        <div
          class="tr">
          <span>Theft</span>
          <span>{{excess.theft}}
          </span>
        </div>
      </div>
      <div v-if="cover_type==2||form.class_id===1004"></div>
      <div
        class="tr">
        <span>TPPD</span>
        <span>{{toFixed(excess.tppd)}}
        </span>
      </div>
      <div
        class="tr">
        <span>TPPD Young Driver</span>
        <span>{{excess.tppd_young_driver|formatMoney(2,true)}}
        </span>
      </div>
      <div
        class="tr">
        <span>TPPD Inexperience Driver</span>
        <span>{{ excess.tppd_inexperienced_driver|formatMoney(2,true)}}
        </span>
      </div>
      <div
        class="tr">
        <span>TPPD Unnamed Driver</span>
        <span>{{excess.tppd_unnamed_driver|formatMoney(2,true)}}
        </span>
      </div>
    </div>
  </section>
</template>

<script>
import MixinFormat from '@/mixins/format'
import MixinOption from '@/mixins/options'
export default {
  name: 'excessInfo',
  mixins: [MixinFormat,MixinOption],
  props:{
    form:{
      type:Object,
      default:()=>({})
    }
  },
  computed:{
    excess(){
      return this.form.excess||{}
    },
    cover_type(){
      let {coverage={}} = this.form
      return coverage.cover_type
    },
  },
  methods:{
    toFixed(data) {
      const arr = String(data).split('.')
      let num = arr[0] // 整数部分
      let resultArr = []
      while (num.length > 0) {
        resultArr.unshift(num.slice(-3))
        num = num.slice(0, -3)
      }
      let result = resultArr.join(',')
      return '$'+result
    }
  }
}
</script>

<style scoped>

</style>
