<template>
  <div class="table">
    <div class="th">
      <span>Vehicle Details</span>
    </div>
    <div
      class="tr">
      <span>Registration Mark</span>
      <span>{{vehicle.registration_mark}}
      </span>
    </div>
    <div
      class="tr">
      <span>Car Make</span>
      <span>{{ getName('carMakeList',vehicle.car_make_id).name }}
      </span>
    </div>
    <div
      class="tr">
      <span>Car Model</span>
      <span>{{vehicle.car_model}}
      </span>
    </div>
    <div
      class="tr">
      <span>Body Type</span>
      <span>{{ getName('carModelList',vehicle.body_type_id).name }}
      </span>
    </div>
    <div
      class="tr">
      <span>Vehicle Cylinder Capacity</span>
      <span>{{vehicle.vehicle_cylinder_capacity}} CC
      </span>
    </div>
    <div
      class="tr">
      <span>Year of Manufacture</span>
      <span>{{vehicle.year_of_manufacture}}
      </span>
    </div>
    <div
      v-if="form.class_id==1002"
      class="tr">
      <span>Permitted Gross Vehicle Weight</span>
      <span>{{ vehicle.permitted_gross_vehicle_weight }} tonnes
      </span>
    </div>
    <div
      class="tr">
      <span>Seating Capacity</span>
      <span>{{ vehicle.searing_capacity }}
      </span>
    </div>
    <div
      class="tr">
      <span>Chassis Number</span>
      <span>{{vehicle.chassis_number}}
      </span>
    </div>
    <div
      class="tr">
      <span>Engine Number</span>
      <span>{{vehicle.engine_number}}
      </span>
    </div>
    <div
      class="tr">
      <span>NCD</span>
      <span>{{vehicle.ncd}}%
      </span>
    </div>
    <div v-if="vehicle.ncd>0">
      <div
        class="tr">
        <span>Policy Number</span>
        <span>{{vehicle.policy_number||'-'}}
        </span>
      </div>
      <div
        class="tr">
        <span>Previous Insurance Company</span>
        <span>{{vehicle.previous_insurance_company||'-'}}
        </span>
      </div>
      <div
        class="tr">
        <span>Registration Number</span>
        <span>{{vehicle.registration_number||'-'}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import MixinFormat from '@/mixins/format'
import MixinOption from '@/mixins/options'
export default {
  name: 'vehicleDetailInfo',
  mixins: [MixinFormat,MixinOption],
  props:{
    form:{
      type:Object,
      default:()=>({})
    }
  },
  computed:{
    vehicle(){
      return this.form.vehicle_detail||{}
    },
    carMakeList(){
      let carList = []
      this.globalOptions.car_make.some(item=>{
        if(item.class_id==this.form.class_id)
          carList = item.option
      })
      return carList
    },
    carModelList(){
      let carList = []
      this.globalOptions.body_type.some(item=>{
        if(item.class_id==this.form.class_id)
          carList = item.option
      })
      return carList
    }
  },
  methods:{
    getName(target, id) {
      let result = {}
      this[target].some(item => {
        if (item.id == id) {
          result = item
          return true
        }
        return false
      })
      return result
    },
  }
}
</script>

<style scoped>

</style>
