<!--Policy Detail Info-->
<template>
  <section>
    <div class="table">
      <div class="th">
        <span>Policy Details</span>
      </div>
      <div
        v-if="!isEndorsement"
        class="tr">
        <span>Insurer</span>
        <span v-if="form.company_id!=0">{{getOptionName('globalOptions.company',form.company_id)}}
        </span>
        <span v-else>{{ form.insurer }}</span>
      </div>
      <div
        v-if="isRenew"
        class="tr">
        <span>Previous Insurer</span>
        <span>{{form.previous_insurer}}
        </span>
      </div>
      <div
        v-if="isRenew"
        class="tr">
        <span>Previous Policy Number</span>
        <span>{{form.previous_policy_number}}
        </span>
      </div>
      <div
        v-if="!isEndorsement&&showOther"
        class="tr">
        <span>Insurance Type</span>
        <span>{{form.insurance_type}}</span>
      </div>
      <div
        v-if="!isEndorsement&&showOther"
        class="tr">
        <span>Product Name</span>
        <span>{{form.product_name}}</span>
      </div>
      <div
        v-if="showMotor&&!isEndorsement"
        class="tr">
        <span>Car Type</span>
        <span>{{getOptionName('carClassOptions',form.class_id)}}</span>
      </div>
      <div
        v-if="!isEndorsement"
        class="tr">
        <span>Client Type</span>
        <span>{{getOptionName('userTypeOptions',form.client_type)}}</span>
      </div>
      <div
        class="tr">
        <span>Issue Date</span>
        <span>{{showHkDate(form.issue_date)}}</span>
      </div>
      <div
        v-if="showTravel"
        class="tr">
        <span>Plan Type</span>
        <span>{{getOptionName('planTypeOptions',form.plan_id)}}</span>
      </div>
      <div
        v-if="showDomestic"
        class="tr">
        <span>Period of Insurance</span>
        <span>{{getOptionName('periodInsuranceOptions',form.period_of_insurance)}}</span>
      </div>
      <div
        class="tr">
        <span>Effective Date</span>
        <span>{{showHKTimeSecond(form.effective_date)}}</span>
      </div>
      <div
        v-if="showTravel&&form.plan_id==5||productId==11"
        class="tr">
        <span>Number of Day</span>
        <span>{{form.number_of_day}}</span>
      </div>
      <div
        class="tr">
        <span>Expiry Date</span>
        <span>{{showHKTimeSecond(form.expiry_date)}}</span>
      </div>
      <div
        v-if="showMaintenance"
        class="tr">
        <span>Maintenance Period</span>
        <span>{{getOptionName('globalOptions.risk_period',form.maintenance_period_id)}}</span>
        <!--        <span v-else>{{// showHKTimeArr(form.maintenance_period_date_start)+'-'+showHKTimeArr(form.maintenance_period_date_end)}}</span>-->
      </div>

      <div
        v-if="showTravel&&form.plan_id==5"
        class="tr">
        <span>Destination</span>
        <span>{{form.destination||'-'}}</span>
      </div>
      <div
        v-if="showTravel&&form.plan_id==5"
        class="tr">
        <span>No. of Person(s) to be Insured</span>
        <span>{{form.insured_person_numbers}}</span>
      </div>
      <div
        v-if="!isEndorsement"
        class="tr">
        <span>Policy Status</span>
        <span>{{form.policy_type==200?'New ':'Renew '}} Policy</span>
      </div>
    </div>
  </section>
</template>

<script>
import MixinOptions from '@/mixins/options'
import {mapActions, mapState} from 'vuex'
import dayjs from 'dayjs'
export default {
  name: 'policyDetailInfo',
  mixins: [MixinOptions],
  props:{
    form:{
      type:Object,
      default:()=>({})
    }
  },
  computed:{
    ...mapState('quotation', ['productID']),
    productId(){
      return this.form.product_id
    },
    showMotor(){
      return this.productId==10
    },
    showMaintenance(){
      return this.productId==11||this.productId==17
    },
    showTravel(){
      return this.productId==12
    },
    showDomestic(){
      return this.productId==16
    },
    showOther(){
      return this.productId==18
    },
    totalDay(){
      let day = dayjs(this.form.expiry_date).diff(dayjs(this.form.effective_date),'day')
      day = day+1
      return day?Math.abs(day):0
    },
    isEndorsement()
    {
      return this.form.policy_type ==203
    },
    isRenew()
    {
      return this.form.policy_type ==201
    },
  }
}
</script>

<style scoped lang="scss">
</style>
