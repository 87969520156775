<template>
  <section>
    <div
      v-if="isCancel"
      class="table">
      <div class="th">
        <span>Notes</span>
      </div>
      <div
        class="tr">
        <!--      <span v-html="remark"></span>-->
        <p v-html="notes"></p>
      </div>
    </div>
    <div class="table">
      <div class="th">
        <span>Remarks</span>
      </div>
      <div
        class="tr">
        <!--      <span v-html="remark"></span>-->
        <p v-html="remark"></p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'remarkInfo',
  props:{
    form:{
      type:Object,
      default:()=>({})
    }
  },
  computed:{
    remark(){
      let {remark} = this.form
      return remark?remark.replace(/\n/g, '<br/>'):'-'
    },
    notes(){
      let {cancellation_notes} = this.form
      return cancellation_notes?cancellation_notes.replace(/\n/g, '<br/>'):'-'
    },
    isCancel(){
      return this.form.policy_type===204
    },
  }
}
</script>

<style scoped>

</style>
