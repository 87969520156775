<template>
  <div class="table">
    <div class="th">
      <span>Documents</span>
    </div>
    <!--    <div-->
    <!--      class="tr">-->
    <!--      <span>Date of Policy Receive</span>-->
    <!--      <span>{{showHkDate(form.policy_receive_date)}}-->
    <!--      </span>-->
    <!--    </div>-->
    <!--    <div-->
    <!--      class="tr">-->
    <!--      <span>Date of Policy Dispatch</span>-->
    <!--      <span>{{showHkDate(form.policy_dispatch_date)}}</span>-->
    <!--    </div>-->
    <!--    <div-->
    <!--      class="tr">-->
    <!--      <span>Dispatch Method</span>-->
    <!--      <span>{{ getOptionName('mathedOptions', form.policy_dispatch_mathed_id)}}</span>-->
    <!--    </div>-->
    <div
      class="tr">
      <span>Quotation <span class="litte-span">Gross Premium</span></span>
      <span
        v-if="loadingDone('quotation_pdf_g_path')"
        class="theme-second-blue hand"
        @click="openFile(quotation_document_gross)">{{quotation_document_gross.client_name||'-'}}</span>
      <span
        v-else
        class="text-center blue icon-loading-size">
        <i class="el-icon-loading"></i>
        <span class="update-text theme-second-blue">Updating</span>
      </span>
    </div>
    <div
      class="tr">
      <span>Quotation <span class="litte-span">Total Payable</span></span>
      <span
        v-if="loadingDone('quotation_pdf_path')"
        class="theme-second-blue hand"
        @click="openFile(quotation_document)">{{quotation_document.client_name||'-'}}</span>
      <span
        v-else
        class="text-center blue icon-loading-size">
        <i class="el-icon-loading"></i>
        <span class="update-text theme-second-blue">Updating</span>
      </span>
    </div>

    <div
      class="tr">
      <span>Invoice  <span class="litte-span">Gross Premium</span> </span>
      <span
        v-if="loadingDone('inv_pdf_g_path')"
        class="theme-second-blue hand"
        @click="openFile(invoice_document_gross)">{{invoice_document_gross.client_name||'-'}}</span>
      <span
        v-else
        class="text-center blue icon-loading-size">
        <i class="el-icon-loading"></i>
        <span class="update-text theme-second-blue">Updating</span>
      </span>
    </div>
    <div
      class="tr">
      <span>Invoice  <span class="litte-span">Total Payable</span> </span>
      <span
        v-if="loadingDone('inv_pdf_path')"
        class="theme-second-blue hand"
        @click="openFile(invoice_document)">{{invoice_document.client_name||'-'}}</span>
      <span
        v-else
        class="text-center blue icon-loading-size">
        <i class="el-icon-loading"></i>
        <span class="update-text theme-second-blue">Updating</span>
      </span>
    </div>
    <div
      class="tr">
      <span>Receipt  <span class="litte-span">Gross Premium</span></span>
      <span
        v-if="loadingDone('rec_pdf_g_path')"
        class="theme-second-blue hand"
        @click="openFile(receipt_document_gross)">{{receipt_document_gross.client_name||'-'}}</span>
      <span
        v-else
        class="text-center blue icon-loading-size">
        <i class="el-icon-loading"></i>
        <span class="update-text theme-second-blue">Updating</span>
      </span>
    </div>
    <div
      class="tr">
      <span>Receipt  <span class="litte-span">Total Payable</span></span>
      <span
        v-if="loadingDone('rec_pdf_path')"
        class="theme-second-blue hand"
        @click="openFile(receipt_document)">{{receipt_document.client_name||'-'}}</span>
      <span
        v-else
        class="text-center blue icon-loading-size">
        <i class="el-icon-loading"></i>
        <span class="update-text theme-second-blue">Updating</span>
      </span>
    </div>
    <div
      class="tr">
      <span>{{isEndorsement?'Endorsement':''}}  Policy Number</span>
      <span>{{form.policy_no}}</span>
    </div>
    <div
      class="tr">
      <span>{{isEndorsement?'Endorsement':''}} Policy</span>
      <span
        v-if="form.policy_document"
        class="theme-second-blue hand"
        @click="openFile(form.policy_document)"
      >{{form.policy_document.client_name}}</span>
    </div>
    <div
      v-if="productId==10"
      class="tr">
      <span>Certificate of Insurance</span>
      <span
        v-if="form.ci_file"
        class="theme-second-blue hand"
        @click="openFile(form.ci_file)"
      >{{form.ci_file.client_name}}</span>
    </div>
    <div
      v-if="productId==10"
      class="tr">
      <span>{{isEndorsement?'Endorsement':''}} Cover Note Number</span>
      <span>{{form.covernote_no}}</span>
    </div>
    <div
      v-if="productId==10"
      class="tr">
      <span>{{isEndorsement?'Endorsement':''}} Cover Note</span>
      <span
        v-if="form.covernote_document"
        class="theme-second-blue hand"
        @click="openFile(form.covernote_document)"
      >{{form.covernote_document.client_name}}</span>
    </div>
    <template   v-if="isCancel">
      <div
        class="tr">
        <span>Cancellation Number</span>
        <span
        >{{form.cancellation_number}}</span>
      </div>
      <div
        v-if="isCancel"
        class="tr">
        <span>Cancellation</span>
        <span
          v-if="form.cancellation_file"
          class="theme-second-blue hand"
          @click="openFile(form.cancellation_file)"
        >{{form.cancellation_file.client_name}}</span>
      </div>
    </template>
    <div
      class="tr">
      <v-checkbox-group
        v-model="form.required_documents"
        disabled
        :list="
          documentList"
      ></v-checkbox-group>
    </div>
    <div
      class="tr">
      <span>Document</span>
      <span>
        <div
          v-for="(item,index) in form.documents"
          :key="index"
          class="theme-second-blue hand"
          @click="openFile(item)">
          <span>{{item.client_name}}</span>
        </div>
      </span>
    </div>
    <div
      v-if="productId==10"
      class="tr">
      <span>Full Set Doc. Received?</span>
      <span>{{getOptionName('yesNoOptions',form.documents_received)}}</span>
    </div>
  </div>
</template>

<script>
import MixinFormat from '@/mixins/format'
import MixinOption from '@/mixins/options'
import {getQuotationStatus} from '@/api/quotation'
export default {
  name: 'documentInfo',
  mixins: [MixinFormat,MixinOption],
  props:{
    form:{
      type:Object,
      default:()=>({})
    },

  },
  data(){
    return{
      setInterval: null,
      isLoading:true,
      loadingData:{},
      document:{}
    }
  },
  computed:{
    loadingDone(){
      return (name) =>{
        if(!this.order_no) return true
        return this.loadingData[name]==0
      }
    },
    productId(){
      return this.form.product_id
    },
    isEndorsement(){
      return this.form.policy_type==203
    },
    isCancel(){
      return this.form.policy_type===204
    },
    order_no(){
      let {order_no} = this.$route.query||{}
      return order_no||''
    },
    documentObj(){
      if(this.order_no)
        return  this.document
      else return  this.form
    },
    quotation_document(){
      return  this.documentObj.quotation_document||{}
    },
    quotation_document_gross(){
      return  this.documentObj.quotation_gross_document||{}
    },
    receipt_document(){
      return  this.documentObj.receipt_document||{}
    },
    receipt_document_gross(){
      return  this.documentObj.receipt_gross_document||{}
    },
    invoice_document(){
      return  this.documentObj.invoice_document||{}
    },
    invoice_document_gross(){
      return  this.documentObj.invoice_gross_document||{}
    },
    documentList(){
      let list = {
        10:this.documentOptions,
        11:this.contractorDocumentOptions,
        12:this.travelDocumentOptions,
        13:this.travelDocumentOptions,
        14:this.employeeDocumentOptions,
        15:this.businessDocumentOptions,
        16:this.travelDocumentOptions,
        17:this.conEmployeeDocumentOptions,
        18:this.travelDocumentOptions
      }
      let {class_id,client_type} = this.form||{}
      let result = []
      this.globalOptions.document_type.some(item=>{

        if (item.class_id == class_id) {
          item.data.some(client=>{
            if(client.client_type==client_type)
              result = client.items
          })
        }
      })
      return result
      return list[this.productId]
    },
  },
  mounted() {
    if(this.order_no)
    {
      this.network().getQuotationStatus()
      this.setInterval = setInterval(() => {
        this.network().getQuotationStatus()
      }, 1000)
    }
  },
  beforeDestroy() {
    clearInterval(this.setInterval)
  },
  methods:{
    updateDoc(){
      if(this.order_no) {
        this.setInterval = setInterval(() => {
          this.network().getQuotationStatus()
        }, 1000)
      }
    },
    openFile(data) {
      const { url } = data
      if(url) {
        window.open(url, '_blank')
      }
    },
    network(){
      return{
        getQuotationStatus:async (params) =>{
          let {order_no} = this.$route.query||{}
          let {data} = await getQuotationStatus({  hideLoading: true,order_no}).catch(err=>{
            if(err){
              clearInterval(this.setInterval)
            }
          })
          this.loadingData = data
          this.document = data.documents||{}
          let documentObj = JSON.parse(JSON.stringify(data))
          delete documentObj.documents
          let isComplete = Object.values(documentObj).every(item=>item==0)
          if(isComplete){
            this.isLoading = false
            clearInterval(this.setInterval)
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
    .theme-second-blue{
      margin-bottom: 8px;
    }
    .litte-span{
      //font-weight: 700;
      font-weight: 400;
      font-size: 12px;
      color: #606266;
    }
    .icon-loading-size{
      height: auto!important;
      justify-content:flex-start!important;
      font-size: 12px!important;
      line-height: 12px;
    }
    .update-text{
      font-size: 12px!important;
      margin-bottom: 0px;
      margin-left: 4px;
    }
</style>
