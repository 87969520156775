<template>
  <div>
    <div
      v-for="(item,index) in form"
      :key="index"
      class="table"
    >
      <div class="th">
        <span>Diver{{index+1}}</span>
      </div>
      <div
        class="tr">
        <span>Relationship with Proposer</span>
        <span>{{ getOptionName('relationshipOptions', item.relationship_id)}}
        </span>
      </div>
      <div
        class="tr">
        <span>Name</span>
        <span>{{item.name}}
        </span>
      </div>
      <div
        class="tr">
        <span>Date of Birth</span>
        <span>{{showHkDate(item.birth)}}
        </span>
      </div>
      <div
        class="tr">
        <span>{{ getOptionName('personalIdTypOptions',item.id_type) }}</span>
        <span>{{item.id_no}}
        </span>
      </div>
      <div
        class="tr">
        <span>Occupation</span>
        <span>{{item.occ}}
        </span>
      </div>
      <div
        class="tr">
        <span>Driving Experience</span>
        <span>{{getOptionName('globalOptions.driving_experience', item.driver_experience_id)}}
        </span>
      </div>

    </div>
  </div>
</template>

<script>
import MixinOption from '@/mixins/options'

export default {
  name: 'diverInfo',
  mixins: [MixinOption],
  props:{
    form:{
      type:Array,
      default:()=>([])
    }
  }
}
</script>

<style scoped>

</style>
