import {mapActions, mapState} from 'vuex'
export default {
  props:{
    formDataLock:{
      type:Number,
      default:0
    }
  },
  data(){
    return{
      oldFormDataLock:0
    }
  },
  watch:{
    formDataLock:{
      immediate:true,
      handler(val){
        if (this.formDataLock !== this.oldFormDataLock) {
          // 觸發更新就賦值，避免二次更新
          this.oldFormDataLock = this.formDataLock
          this.$nextTick(()=>{
            if(this.$refs.document)
              this.$refs.document.updateDoc()
          })
        }
      }
    }
  }
}
