<template>
  <section>
    <policy-detail-info :form="formData"></policy-detail-info>
    <policy-holder-detail-info :form="formData"></policy-holder-detail-info>
    <coverage-info :form="formData"></coverage-info>
    <excess-info :form="formData"></excess-info>
    <div v-if="formData.class_id!==1004">
      <vehicle-detail-info :form="formData" ></vehicle-detail-info>
    </div>
    <div v-if="formData.class_id!==1004||formData.class_id===1004&&coverage.cover_type==4">
      <driver-info :form="formData.drivers"></driver-info>
    </div>
    <remark-info :form="formData"></remark-info>
    <document-info
      ref="document"
      :form="formData"></document-info>
    <referral-info :form="formData.referral"></referral-info>
  </section>
</template>

<script>
import policyDetailInfo from '@/views/components/info/policyDetailInfo'
import coverageInfo from '@/views/components/info/coverageInfo'
import policyHolderDetailInfo from '@/views/components/info/policyHolderDetailInfo'
import excessInfo from '@/views/components/info/excessInfo'
import vehicleDetailInfo from '@/views/components/info/vehicleDetailInfo'
import driverInfo from '@/views/components/info/driverInfo'
import remarkInfo from '@/views/components/info/remarkInfo'
import documentInfo from '@/views/components/info/documentInfo'
import referralInfo from '@/views/components/info/referralInfo'
import MixinUpdateDoc from '@/mixins/update-doc'

export default {
  name: 'motoInfo',
  components:{
    policyDetailInfo,
    coverageInfo,
    policyHolderDetailInfo,
    excessInfo,
    vehicleDetailInfo,
    driverInfo,
    remarkInfo,
    documentInfo,
    referralInfo,
  },
  mixins: [MixinUpdateDoc],
  props:{
    formData:{
      type:Object,
      default:()=>({})
    }
  },
  data(){
    return{
      // formData:{
      //   payment:{}
      // },
    }
  },
  computed:{
    coverage(){
      return this.formData.coverage||{}
    }
  },
}
</script>

<style scoped>

</style>
